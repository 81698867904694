<script>
import {
  required,
  /*email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,*/
} from "vuelidate/lib/validators";

//import Swal from "sweetalert2";
//import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
//import NumberInputSpinner from "vue-number-input-spinner";
import useNetw from "@useNetw";
import Swal from "sweetalert2";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    /*DatePicker,
    Switches,
    NumberInputSpinner,*/
  },

  computed: {
    /**
     * Total no. of records
     */
    rowsWage() {
      return this.rowDataWage.length;
    },
    rowsPart() {
      return this.rowDataPart.length;
    },
    rowsVm() {
      return this.rowDataVm.length;
    },
    //
  },

  mounted() {
    // this.totalRowsWage = this.rowDataWage.items.length;
    // this.totalRowsWagesl = this.RowDataWagesl.items.length;
    // this.totalRowsPart = this.rowDataPart.items.length;
    // this.totalRowsWaPart = this.rowDataPartsl.items.length;
    // this.totalRowsVm = this.rowDataVm.items.length;
    this.totalRowsWage = this.items.length;
    this.totalRowsWagesl = this.items.length;
    this.totalRowsPart = this.items.length;
    this.totalRowsWaPart = this.items.length;
    this.totalRowsVm = this.items.length;
  },

  created() {
    this.getLocalData();
    this.getDataPackage();
    this.getDataWage();
    this.getDataPart();
    this.getDataSd();
    this.getDataModel();
    this.getPackageWage();
    this.getPackagePart();
    this.totalPriceWage();
    this.totalPricePart();
    this.totalPricePackage();
    this.getDataVm();
  },

  data() {
    return {
      submitformPackage: false,

      title: "แก้ไขแพ็คเกจ",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "ศูนย์บริการ",
        },
        {
          text: "แพ็คเกจการซ่อม",
          href: "package/mainPackage"
        },
        {
          text: "แก้ไขแพ็คเกจการซ่อม",
          active: true,
        },
        
      ],
      wageTo: "",
      wagefrom: '',
      partTo:'',
      partFrom:'',
      vmFrom:'',
      vmTo:'',



      localDataBranch:[],
      localData:[],
      showOverlayFormPackage: false, //?โหลดตอน post
      showOverlayFormPackagePart: false,
      showOverlayFormPackageWage: false,
      showOverlayFormPackageConclude: false,
      showOverlayFormPackageVm: false,
      loading: undefined, //?โหลด
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      SuccessPostPackage: true,

      //? ตัวแปรรวมของตาราง

      sortDesc: false,
      sortBy: "id", //? เรียงจาก
      selectMode: "multi", //?  โหมดการเลือก
      Model: [], //? show models car
      totalPricesWage: 0.0, // ?ยอดรวมราคาค่าแรง
      totalPricesPart: 0.0, // ?ยอดรวมราคาค่าอะไหล่
      packageTotalPrice: 0.0, // ?ยอดรวมราคาทั้งหมด

      //! paramiter of table Wage
      //* Starts table Wage (ตัวแปรตารางค่าแรง)
      rowDataWage: [], //? Data ที่ต่อ API
      perPageWage: 10, //?จำนวต่อหน้า
      currentPageWage: 1, //?หน้าปัจจุบัน
      filterWage: null, // ?คำค้นหา
      filterOnWage: [], //?ตัวกรอง
      pageOptionsWage: [5, 10, 25, 50, 100], //? แสดงกี่คอลัมน์ต่อหน้า
      total_pagesWage: "", //?จำนวนหน้าทั้งหมด
      pageWage: "", //?หน้าที่

      totalWage: "", //? จำนวนหน้าทั้งหมด
      totalRowsWage: 1,
      selectedWage: [],
      selectedWage2: [],
      packageId: this.$route.params.packageId,
      fieldsWage: [
        //? field ตารางค่าแรง
        {
          key: "selected",
          label: "เลือก",
          active: true,
        },
        {
          key: "wageCode",
          label: "รหัสค่าแรง",
          sortable: true,
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อค่าแรง",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา / ชั่วโมง",
          formatter: (key) => Number(key).toLocaleString(),
        },
      ],

      //! end table

      //! Starts table Wage selected (ตัวแปรตารางค่าแรง - เลือกแล้ว)
      rowDataWageslPut: [],
      rowDataWagesl: [], //? Data จากอีกตาราง
      perPageWagesl: 10, //?จำนวต่อหน้า
      currentPageWagesl: 1, //?หน้าปัจจุบัน
      filterWagesl: null, // ?คำค้นหา
      filterOnWagesl: [], //?ตัวกรอง
      pageOptionsWagesl: [5, 10, 25, 50, 100], //? แสดงกี่คอลัมน์ต่อหน้า
      total_pagesWagesl: "", //?จำนวนหน้าทั้งหมด
      pageWagesl: "", //?หน้าที่
      totalWagesl: "", //? จำนวนหน้าทั้งหมด
      totalRowsWagesl: 1,
      selectedWagesl: [],

      fieldsWagesl: [
        //? field ตารางค่าแรง
        {
          key: "wageCode",
          label: "รหัสค่าแรง",
          sortable: true,
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อค่าแรง",
        },
        {
          key: "amount",
          sortable: true,
          label: "จำนวน (ชั่วโมง)",
        },
        {
          key: "total_price",
          sortable: true,
          label: "ราคารวม",
          formatter: (key) => Number(key).toLocaleString(),
        },
        {
          key: "action",
          label: "",
        },
      ],

      //! end table

      //? paramiter of table Part
      //* Starts table Parts (ตัวแปรตารางอะไหล่)
      //! paramiter of table Parts
      rowDataPart: [], //? Data ที่ต่อ API
      perPagePart: 10, //?จำนวต่อหน้า
      currentPagePart: 1, //?หน้าปัจจุบัน
      filterPart: null, // ?คำค้นหา
      filterOnPart: [], //?ตัวกรอง
      pageOptionsPart: [5, 10, 25, 50, 100], //? แสดงกี่คอลัมน์ต่อหน้า
      total_pagesPart: "", //?จำนวนหน้าทั้งหมด
      pagePart: "", //?หน้าที่

      totalPart: "", //? จำนวนหน้าทั้งหมด
      totalRowsPart: 1,
      selectedPart: [],

      fieldsPart: [
        //? field ตารางค่าแรง
        {
          key: "selected",
          label: "เลือก",
          active: true,
        },
        {
          key: "partCode",
          label: "รหัสอะไหล่",
          sortable: true,
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่ออะไหล่",
        },
        {
          key: "costPrice",
          sortable: true,
          label: "ราคาต้นทุน",
        },
        {
          key: "sellPrice",
          sortable: true,
          label: "ราคาขาย",
          formatter: (key) => Number(key).toLocaleString(),
        },
      ],
      //! end table

      //! Starts table Part selected (ตัวแปรตารางอะไหล่ - เลือกแล้ว)
      rowDataPartsl: [], //? Data จากอีกตาราง
      perPagePartsl: 10, //?จำนวต่อหน้า
      currentPagePartsl: 1, //?หน้าปัจจุบัน
      filterPartsl: null, // ?คำค้นหา
      filterOnPartsl: [], //?ตัวกรอง
      pageOptionsPartsl: [5, 10, 25, 50, 100], //? แสดงกี่คอลัมน์ต่อหน้า
      total_pagesPartsl: "", //?จำนวนหน้าทั้งหมด
      pagePartsl: "", //?หน้าที่

      totalPartsl: "", //? จำนวนหน้าทั้งหมด
      totalRowsPartsl: 1,
      selectedPartsl: [],
      rowDataSd: [],

      fieldsPartsl: [
        //? field ตารางค่าแรง

        {
          key: "partCode",
          label: "รหัสอะไหล่",
          sortable: true,
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่ออะไหล่",
        },
        {
          key: "total_price",
          sortable: true,
          label: "ราคารวม",
          formatter: (key) => Number(key).toLocaleString(),
        },
        {
          key: "action",
          label: "",
        },
      ],

      //! paramiter of table Vm
      rowDataVm: [], //? Data ที่ต่อ API
      perPageVm: 10, //?จำนวต่อหน้า
      currentPageVm: 1, //?หน้าปัจจุบัน
      filterVm: null, // ?คำค้นหา
      filterOnVm: [], //?ตัวกรอง
      pageOptionsVm: [5, 10, 25, 50, 100], //? แสดงกี่คอลัมน์ต่อหน้า
      total_pagesVm: "", //?จำนวนหน้าทั้งหมด
      pageVm: "", //?หน้าที่
      totalVm: "", //? จำนวนหน้าทั้งหมด
      totalRowsVm: 1,
      selectedVm: [],

      fieldsVm: [
        //? field ตารางรถ
        {
          key: "nameTh",
          sortable: true,
          label: "รุ่นรถที่่สามารใช้ในแพ็คเกจได้",
        },
        {
          key: "action",
          label: "ลบ",
        },
      ],

      //! end table
      // branchNameP:"",

      WagePostForm: {
        wageIdP: "",
        amount: 1,
        amountP: 1,
        nameTh: 0,
        packageWagePrice: 0,
        totalPrice: 0,
        price: 0,
        pricePerOne: 0,
        packageTotalPrice: 0,
        packagePartPrice: 0,
      },

      WagePutForm: {
        wageIdP: "",
        amount: 1,
        amountP: 1,
        nameTh: 0,
        packageWagePrice: 0,
        totalPrice: 0,
        price: 0,
        pricePerOne: 0,
        packageTotalPrice: 0,
        packagePartPrice: 0,
        amountDefaultWage: 0,
      },

      PartPostForm: {
        partIdP: "",
        amountP: 1,
        amount: 1,
        nameTh: "",
        packageWagePrice: 0,
        totalPrice: 0,
        price: 0,
        pricePerOne: 0,
        packageTotalPrice: 0,
        packagePartPrice: 0,
      },
      PartPutForm: {
        partIdP: "",
        amountP: 1,
        amount: 1,
        nameTh: "",
        packageWagePrice: 0,
        totalPrice: 0,
        price: 0,
        pricePerOne: 0,
        packageTotalPrice: 0,
        packagePartPrice: 0,
        amountDefaultPart: 0,
      },

      modelType: [],
      formpackage: {
        packageCode: "",
        nameTh: "",
        nameEn: "",
        descriptionTh: "",
        descriptionEn: "",
        useInGs: "",
        useInBP: "",
        useInWc: "",
        brand: ["Mazda"],
        packageType: [],
        brands: "",
      },
    };
  },
  validations: {
    formpackage: {
      packageCode: {},
      nameTh: {
        required,
      },
      nameEn: {},
      descriptionTh: {},
      descriptionEn: {},
      brands: {},
      modelType: {},
      packageType: { required },
      useInGs: {},
      useInBP: {},
      useInWc: {},
    },
  },

  methods: {
    getLocalData(){

      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch=[];
      this.localData.forEach( item => {
        localDataBranch.push(item.branchId)
      })
      
      this.localDataBranch = localDataBranch;
      // console.log(this.localDataBranch);
    

    },
    getDataVm: function() {
      this.showOverlayFormPackageVm = true;
      useNetw
        .get("api/package/vehicle-models", {
          params: {
            page: this.currentPageVm,
            perPage: this.perPageVm,
            packageId: this.$route.params.packageId,
          },
        }) // เอามาจากไหน

        .then((response) => {
          // console.log('Vm ===> ', response.data);
          this.rowDataVm = response.data.data; //?ข้อมูล
          this.per_pageVm = response.data.perPage; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          this.pageVm = response.data.page; // ?หน้าที่เท่าไหร่
          this.vmFrom = response.data.from;
          this.vmTo = response.data.to;
          this.total_pagesVm = response.data.total_page; //?หน้าทั้งหมด
          this.totalVm = response.data.total; //? จำนวนข้อมูลทั้งหมด
          // console.log("rowDataVm = ", response);
        })
        .catch((err) => {
          //ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.showOverlayFormPackageVm = false;
        });
    },
    ConfirmDelVm(rowDataVm) {
      Swal.fire({
        title: "ต้องการยกเลิกรุ่นรถกับแพ็คเกจนี้ใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ลบ",
      }).then((result) => {
        if (result.value) {
          this.delVm(rowDataVm);
        }
      });
    },

    delVm: function(rowDataVm) {
      this.rowDataVm = rowDataVm;
      this.modelIdDel = rowDataVm.item.model_id;

      useNetw
        .delete("api/package/vehicle-model/delete", {
          data: {
            packageId: this.$route.params.packageId,
            modelId: this.modelIdDel,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataVm();
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.showOverlayFormPackageWage = false; //skeleton false
        });
    },

    getDataPackage: function() {
      // this.overlayFlag = true;
      this.showOverlayFormPackagePart = true; //skeleton true
      useNetw
        .get("api/package/show", {
          params: {
            packageId: this.$route.params.packageId,
          },
        })
        .then((response) => {
          //con
          // console.log("packageUpdate =  ", response);
          this.branchId = response.data.data.branchId;
          this.formpackage.branchId = response.data.data.branchId;
          this.formpackage.branchId = response.data.data.branchId;
          this.formpackage.packageCode = response.data.data.editableCode;
          this.formpackage.nameTh = response.data.data.nameTh;
          this.formpackage.nameEn = response.data.data.nameEn;
          this.formpackage.useInGs = response.data.data.useInGs;
          this.formpackage.useInBp = response.data.data.useInBp;
          this.formpackage.useInWc = response.data.data.useInWc;
          this.formpackage.packageType = {
            distanceId: response.data.data.distanceId,
            nameTh: response.data.data.distanceNameTh,
          };
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.showOverlayFormPackagePart = false; //skeleton false
        });
    },

    getDataSd: function() {
      this.loading = true;
      useNetw
        .get("api/master/service-distances", {}) // เอามาจากไหน

        .then((response) => {
          this.rowDataSd = response.data.data;
          // console.log("SD = ", this.rowDataSd);
        })
        .catch((err) => {
          //ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },

    getDataModel: function() {
      this.loading = true;
      useNetw
        .get("api/vehicle-model", {
          params: {
            page:1,
            perPage:10,
            branchId: this.localDataBranch,
          },
        }) // เอามาจากไหน

        .then((response) => {
          this.Model = response.data.data;
          this.per_page = response.data.per_page; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          this.page = response.data.page; // ?หน้าที่เท่าไหร่
          this.total_pages = response.data.total_pages; //?หน้าทั้งหมด
          this.total = response.data.total; //? จำนวนข้อมูลทั้งหมด
          // console.log("Model = ", this.Model);
        })
        .catch((err) => {
          //ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },

    formPackage() {
      
      this.$v.$touch();

      if (this.$v.formpackage.$invalid) {
        // console.log("false");
        this.submitformPackage = true;
        
      } else {
          this.putPackage();
      }
    },

    putPackage: function() {
      useNetw
        .put("/api/package/update", {
          packageId: this.$route.params.packageId,
          branchId: this.formpackage.branchId,
          editableCode: this.formpackage.packageCode,
          nameTh: this.formpackage.nameTh,
          nameEn: this.formpackage.nameEn,
          descriptionTh: this.formpackage.descriptionTh,
          descriptionEn: this.formpackage.descriptionEn,
          useInGs: this.formpackage.useInGs,
          useInBp: this.formpackage.useInBp,
          useInWc: this.formpackage.useInWc,
          wagePrice: this.totalPricesWage,
          partPrice: this.totalPricesPart,
          totalPrice: this.packageTotalPrice,
          distanceId: this.formpackage.packageType.distanceId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "mainPackage" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    postVm: function() {
      this.showOverlayFormPackageVm = true;
      const VmFormData = new FormData();
      VmFormData.append("packageId", this.$route.params.packageId);
      VmFormData.append("modelId", this.modelType.modelId);

      useNetw
        .post("api/package/vehicle-model/store", VmFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.showOverlayFormPackageVm = false;
          this.modelType = [];
          this.getDataVm();
          this.showOverlayFormPackageVm = false;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.showOverlayFormPackageVm = false;
          // this.$router.push({ name: "main-user" });
        });
    },

    //? ฟังก์ชันตาราง

    //! Start function of table Wage (ฟังก์ชันเกี่ยวกับตารางค่าแรง)
    getDataWage: function() {
      this.showOverlayFormPackageWage = true;
      useNetw
        .get("api/wage", {
          params: {
            page: this.currentPageWage,
            perPage: this.perPageWage,
            branchId: this.localDataBranch,
          },
        }) // เอามาจากไหน

        .then((response) => {
          //?ได้รับการตอบกลับ
          //?ถ้าได้ข้อมูลมาหรือได้รับการตอบกลับให้ทำอะไร
          // console.log('wages ===> ' , response.data);
          this.rowDataWage = response.data.data; //?ข้อมูล
          this.perPageWage = response.data.perPage; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          this.pageWage = response.data.page; // ?หน้าที่เท่าไหร่
          this.wagefrom = response.data.from;
          this.wageTo = response.data.to;
          this.total_pagesWage = response.data.total_pages; //?หน้าทั้งหมด

          this.totalWage = response.data.total; // ?จำนวนข้อมูลทั้งหมด
          // console.log(response);
        })
        .catch((err) => {
          //ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.showOverlayFormPackageWage = false;
        });
    },

    handleChangePageWage(pageWage) {
      this.currentPageWage = pageWage;
      this.getDataWage();
    },
    handlePageChangeWage(active) {
      this.perPageWage = active;
      this.currentPageWage = 1;
      this.getDataWage();
    },
    onFilteredWage(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsWage = filteredItems.length;
      this.currentPageWage = 1;
    },

    onRowSelectedWage(rowDataWage) {
      this.selectedWage = rowDataWage;
      this.WagePostForm.nameTh = this.selectedWage[0].nameTh;
      this.$refs.modalWageAmount.show();
      // console.log(this.nameTh);
    },
    selectAllRowsWage() {
      this.$refs.selectableTableWage.selectAllRows();
    },
    clearSelectedWage() {
      this.$refs.selectableTableWage.clearSelected();
      this.WagePostForm.nameTh = " ข้อมูล ";
      this.WagePostForm.amountP = 1;
      this.selectedWage[0].nameTh = "";
    },

    postWage: function() {
      this.hidemodalWage();
      this.showOverlayFormPackageWage = true;
      // console.log(this.WagePostForm.amountP);
      // console.log(this.selectedWage);
      this.WagePostForm.totalPrice =
        this.selectedWage[0].price * this.WagePostForm.amountP;
      // console.log(this.WagePostForm.totalPrice);
      this.packageTotalPrice = this.totalPricesWage + this.totalPricesPart;
      // !this.showOverlayFormPackage = true;
      const WagePackageFormData = new FormData();

      WagePackageFormData.append("branchId", this.formpackage.branchId);
      WagePackageFormData.append("packageId", this.$route.params.packageId);
      WagePackageFormData.append("wageId", this.selectedWage[0].wageId);
      WagePackageFormData.append("amount", this.WagePostForm.amountP);
      WagePackageFormData.append("pricePerOne", this.selectedWage[0].price);
      WagePackageFormData.append("totalPrice", this.WagePostForm.totalPrice);
      WagePackageFormData.append(
        "packageWagePrice",
        this.WagePostForm.packageWagePrice
      );
      WagePackageFormData.append(
        "packagePartPrice",
        this.WagePostForm.packagePartPrice
      );
      WagePackageFormData.append("packageTotalPrice", this.packageTotalPrice);

      useNetw
        .post("api/package/wage/store", WagePackageFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.totalPriceWage();
          this.packageTotalPrice = this.totalPricesWage + this.totalPricesPart;
          // this.showOverlayFormPackage = false;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.showOverlayFormPackageWage = false;
          this.getPackageWage();
          this.totalPriceWage();
          this.totalPricePart();
          this.totalPricePackage();
          this.clearSelectedWage();
        });
    },
    hidemodalWage() {
      this.$refs.modalWageAmount.hide();
    },

    // SeeDeldata(rowDataWagesl) {
    //   // console.log(rowDataWagesl);
    // },

    ConfirmDelWage(rowDataWagesl) {
      Swal.fire({
        title: "ต้องการลบค่าแรงใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ลบ",
      }).then((result) => {
        if (result.value) {
          this.delWage(rowDataWagesl);
        }
      });
    },

    ConfirmDelPart(rowDataPartsl) {
      Swal.fire({
        title: "ต้องการลบค่าอะไหล่ใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ลบ",
      }).then((result) => {
        if (result.value) {
          this.delPart(rowDataPartsl);
        }
      });
    },

    delWage: function(rowDataWagesl) {
      // console.log("rowDataWagesl", rowDataWagesl);
      // console.log(rowDataWagesl);
      this.rowDataWagesl = rowDataWagesl;
      this.wageIdDel = rowDataWagesl.item.wage_id;
      this.packageTotalPrice = this.totalPricesWage + this.totalPricesPart;
      this.showOverlayFormPackageWage = true;
      useNetw
        .delete("api/package/wage/delete", {
          data: {
            packageId: this.$route.params.packageId,
            wageId: this.wageIdDel,
            packageWagePrice: this.totalPricesWage,
            packagePartPrice: this.totalPricesPart,
            packageTotalPrice: this.packageTotalPrice,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getPackageWage();
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.showOverlayFormPackageWage = false; //skeleton false
        });
    },

    // putWage

    getPackageWage: function() {
      useNetw
        .get("api/package/wages", {
          params: {
            page: this.currentPageWage,
            perPage: this.perPageWagesl,
            packageId: this.$route.params.packageId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          //?ได้รับการตอบกลับ
          //?ถ้าได้ข้อมูลมาหรือได้รับการตอบกลับให้ทำอะไร
          // this.packageId = response.data.data.packageId;
          // console.log('WagesSL ===> ', response.data);
          this.totalWagesl = response.data.total;
          this.rowDataWagesl = response.data.data; //?ข้อมูล
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.totalPriceWage();
        });
    },
    totalPricePackage() {
      this.packageTotalPrice = this.totalPricesWage + this.totalPricesPart;
    },
    totalPriceWage() {
      let sum = 0;
      this.rowDataWagesl.forEach(function(item) {
        sum += parseFloat(item.total_price);
      });
      this.totalPricesWage = sum;
      this.totalSelectedWage = this.rowDataWagesl.length;
      // console.log("totalProces = ", this.totalPricesWage);
      // console.log("RDWSL = ", this.rowDataWagesl);
      this.totalPricePackage();
    },

    totalPricePart() {
      let sum = 0;
      this.rowDataPartsl.forEach(function(item) {
        sum += parseFloat(item.total_price);
      });
      this.totalPricesPart = sum;
      this.totalSelectedPart = this.rowDataPartsl.length;
      // console.log("totalProces = ", this.totalPricesPart);
      // console.log("RDPSL = ", this.rowDataPartsl);
      this.totalPricePackage();
    },

    getPackagePart: function() {
      this.showOverlayFormPackagePart = true;
      useNetw
        .get("api/package/parts", {
          params: {
            page: this.currentPagePartsl,
            perPage: this.perPagePartsl,
            packageId: this.$route.params.packageId,
            // nameTh: "แพ็คเกจ",
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          //?ได้รับการตอบกลับ
          //?ถ้าได้ข้อมูลมาหรือได้รับการตอบกลับให้ทำอะไร
          // this.packageId = response.data.data.packageId;
          this.rowDataPartsl = response.data.data; //?ข้อมูล
          this.per_page = response.data.per_page; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          this.page = response.data.page; // ?หน้าที่เท่าไหร่
          this.total_pages = response.data.total_pages; //?หน้าทั้งหมด
          this.totalPartsl = response.data.total; //? จำนวนข้อมูลทั้งหมด
          // console.log("packagePaer = ", response);
          this.totalPricePart();
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.showOverlayFormPackagePart = false;
        });
    },

    //! End table function Wage

    //! Start function of table Wage selected (ฟังก์ชันเกี่ยวกับตารางค่าแรง - เลือกแล้ว)

    handleChangePageWagesl(pageWagesl) {
      this.currentPageWagesl = pageWagesl;
    },
    handlePageChangeWagesl(active) {
      this.perPageWagesl = active;
      this.currentPageWagesl = 1;
    },
    onFilteredWagesl(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsWagesl = filteredItems.length;
      this.currentPageWagesl = 1;
    },
    onRowSelectedWagesl(rowDataWagesl) {
      this.selectedWagesl = rowDataWagesl;
    },

    editPutWage(rowDataWagesl) {
      this.$refs.modalEditWageAmount.show();
      // this.rowDataWageslPut = rowDataWagesl;
      this.WagePutForm.partIdP = rowDataWagesl.item.wage_id;
      this.WagePutForm.nameTh = rowDataWagesl.item.nameTh;
      this.WagePutForm.pricePerOne = rowDataWagesl.item.price_per_one;
      this.WagePutForm.amountDefaultWage = rowDataWagesl.item.amount;

      // console.log(this.rowDataPartsl.item);
      // console.log("WageIdEdit = ", this.WagePutForm.partIdP);

      this.getPackageWage();
    },

    putWage: function() {
      this.showOverlayFormPackageWage = true;
      useNetw
        .put("api/package/wage/update", {
          packageId: this.$route.params.packageId,
          wageId: this.WagePutForm.partIdP,
          // branchId: 1,
          amount: this.WagePutForm.amountP,
          pricePerOne: this.WagePutForm.pricePerOne,
          totalPrice: this.WagePutForm.pricePerOne * this.WagePutForm.amountP,
          packageWagePrice: this.WagePutForm.packageWagePrice,
          packagePartPrice: this.WagePutForm.packagePartPrice,
          packageTotalPrice: this.totalPricesWage + this.totalPricesPart,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getPackageWage();

          this.packageTotalPrice = this.totalPricesWage + this.totalPricesPart;
          // this.$router.push({ name: "mainPackage" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.hidemodalEditWage();
          this.showOverlayFormPackageWage = false;
          this.getPackagePart();
          this.totalPriceWage();
          this.totalPricePart();
          this.totalPricePackage();
        });
    },
    hidemodalEditWage() {
      this.$refs.modalEditWageAmount.hide();
    },

    //! End Function of Wage selected

    //** New Function of parts */
    //! Start function of table Part (ฟังก์ชันเกี่ยวกับตารางค่าอะไหล่)

    getDataPart: function() {
      this.showOverlayFormPackagePart = true;
      useNetw
        .get("api/part", {
          params: {
            page: this.currentPagePart,
            perPage: this.perPagePart,
            branchId:this.localDataBranch,
          },
        }) // เอามาจากไหน

        .then((response) => {
          //?ได้รับการตอบกลับ
          //?ถ้าได้ข้อมูลมาหรือได้รับการตอบกลับให้ทำอะไร
          // console.log('Part=' , response.data);
          this.rowDataPart = response.data.data; //?ข้อมูล
          this.perPagePart = response.data.perPage; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          this.pagePart = response.data.page; // ?หน้าที่เท่าไหร่
          this.partFrom = response.data.from;
          this.partTo = response.data.to;
          this.total_pagesPart = response.data.total_pages; //?หน้าทั้งหมด
          this.totalPart = response.data.total; // ?จำนวนข้อมูลทั้งหมด
          // console.log(response.data.data);
        })
        .catch((err) => {
          //ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.showOverlayFormPackagePart = false;
        });
    },

    handleChangePagePart(pagePart) {
      this.currentPagePart = pagePart;
      this.getDataPart();
    },
    handlePageChangePart(active) {
      this.perPagePart = active;
      this.currentPagePart = 1;
      this.getDataPart();
    },
    onFilteredPart(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsPart = filteredItems.length;
      this.currentPagePart = 1;
    },

    onRowSelectedPart(rowDataPart) {
      this.selectedPart = rowDataPart;
      this.PartPostForm.nameTh = this.selectedPart[0].nameTh;
      this.$refs.modalPartAmount.show();

      // this.postPartAmount();
    },
    selectAllRowsPart() {
      this.$refs.selectableTablePart.selectAllRows();
    },
    clearSelectedPart() {
      this.$refs.selectableTablePart.clearSelected();
      this.PartPostForm.nameTh = " ข้อมูล ";
      this.PartPostForm.amountP = 1;
      this.selectedPart[0].nameTh = "";
    },

    hideModalPart() {
      this.$refs.modalPartAmount.hide();
    },

    postPart: function() {
      this.showOverlayFormPackagePart = true;
      this.hideModalPart();
      // console.log(this.PartPostForm.amountP);
      // console.log(this.selectedPart);
      this.PartPostForm.totalPrice =
        this.selectedPart[0].sellPrice * this.PartPostForm.amountP;
      // console.log(this.PartPostForm.totalPrice);
      this.packageTotalPrice = this.totalPricesWage + this.totalPricesPart;
      // !this.showOverlayFormPackage = true;
      const PartPackageFormData = new FormData();
      PartPackageFormData.append("branchId", this.formpackage.branchId);
      PartPackageFormData.append("packageId", this.$route.params.packageId);
      PartPackageFormData.append("partId", this.selectedPart[0].partId);
      PartPackageFormData.append("amount", this.PartPostForm.amountP);
      PartPackageFormData.append("pricePerOne", this.selectedPart[0].sellPrice);
      PartPackageFormData.append("totalPrice", this.PartPostForm.totalPrice);
      PartPackageFormData.append(
        "packageWagePrice",
        this.PartPostForm.packageWagePrice
      );
      PartPackageFormData.append(
        "packagePartPrice",
        this.PartPostForm.packagePartPrice
      );
      PartPackageFormData.append("packageTotalPrice", this.packageTotalPrice);

      useNetw
        .post("api/package/part/store", PartPackageFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          this.totalPricePart();
          this.totalPricePackage();

          // this.$refs.selectedPart.clearSelected();
        })
        .then(() => {
          // this.$refs.selectedPart.clearSelected();
          this.showOverlayFormPackagePart = false;
          this.getPackagePart();

          this.totalPriceWage();
          this.totalPricePart();
          this.clearSelectedPart();

          // this.PartPostForm.amountP = 1;
        });
    },
    // seedatadel(rowDataPartsl) {
    //   console.log("rowDataPartsl", rowDataPartsl);
    // },
    delPart: function(rowDataPartsl) {
      // console.log("rowDataPartsl = ", rowDataPartsl);
      // console.log(wageId);
      // this.rowDataPartsl = rowDataPartsl;
      this.partIdDel = rowDataPartsl.item.part_id;
      this.packageTotalPrice = this.totalPricesWage + this.totalPricesPart;
      this.showOverlayFormPackagePart = true; //skeleton true
      useNetw
        .delete("api/package/part/delete", {
          data: {
            packageId: this.$route.params.packageId,
            partId: this.partIdDel,
            packageWagePrice: this.totalPricesWage,
            packagePartPrice: this.totalPricesPart,
            packageTotalPrice: this.packageTotalPrice,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getPackagePart();
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.showOverlayFormPackagePart = false; //skeleton false
        });
    },

    editPutpart(rowDataPartsl) {
      this.$refs.modalEditPartAmount.show();
      this.rowDataPartsl = rowDataPartsl;
      this.PartPutForm.partIdP = rowDataPartsl.item.part_id;
      this.PartPutForm.nameTh = rowDataPartsl.item.nameTh;
      this.PartPutForm.pricePerOne = rowDataPartsl.item.price_per_one;
      this.PartPutForm.amountDefaultPart = rowDataPartsl.item.amount;

      // console.log(this.rowDataPartsl.item);
      // console.log("partIdEdit = ", this.PartPutForm.partIdP);

      // this.getPackagePart();
    },

    putPart: function() {
      this.showOverlayFormPackagePart = true;
      useNetw
        .put("api/package/part/update", {
          packageId: this.$route.params.packageId,
          partId: this.PartPutForm.partIdP,
          // branchId: 1,
          amount: this.PartPutForm.amountP,
          pricePerOne: this.PartPutForm.pricePerOne,
          totalPrice: this.PartPutForm.pricePerOne * this.PartPutForm.amountP,
          packageWagePrice: this.PartPostForm.packageWagePrice,
          packagePartPrice: this.PartPostForm.packagePartPrice,
          packageTotalPrice: this.totalPricesWage + this.totalPricesPart,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getPackagePart();
          // this.$router.push({ name: "mainPackage" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.hidemodalEditPart();
          this.showOverlayFormPackagePart = false;
          this.getPackagePart();
          this.totalPriceWage();
          this.totalPricePart();
          this.totalPricePackage();
        });
    },
    hidemodalEditPart() {
      this.$refs.modalEditPartAmount.hide();
    },

    //! End table function Part
    //* ---
    //! Start function of table Partsl selected (ฟังก์ชันเกี่ยวกับตารางค่าอะไหล่ - เลือกแล้ว)

    handleChangePagePartsl(pagePartsl) {
      this.currentPagePartsl = pagePartsl;
    },
    handlePageChangePartsl(active) {
      this.perPage = active;
      this.currentPagePartsl = 1;
    },
    onFilteredPartsl(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsPartsl = filteredItems.length;
      this.currentPagePartsl = 1;
    },
    onRowSelectedPartsl(rowDataPartsl) {
      this.selectedPartsl = rowDataPartsl;
    },

    //! End Function of Partsl selected

    handleChangePageVm(pageVm) {
      this.currentPageVm = pageVm;
      this.getDataVm();
    },
    handlePageChangeVm(active) {
      this.perPageVm = active;
      this.currentPageVm = 1;
      this.getDataVm();
    },
    onFilteredVm(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsVm = filteredItems.length;
      this.currentPageVm = 1;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <br />
            <b-skeleton animation="wave" width="10%"></b-skeleton>
            <b-skeleton animation="wave" width="15%"></b-skeleton>

            <br />
            <div class="row">
              <div class="col-md-2">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />
            <div class="row">
              <br />
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />

            <div class="card">
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <!--// !skeleton ends-->

          <div class="card">
            <b-overlay
              :show="showOverlayFormPackage"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <form class="needs-validation" @submit.prevent="formPackage">
                <div class="card-body">
                  <div class="row">
                    <div class="text-end">
                      <router-link :to="{ name: 'mainPackage' }">
                        <button class="btn btn-secondary">
                          กลับสู่หน้าหลัก
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <hr />
                  <h4 class="card-title">ข้อมูลแพ็คเกจ</h4>

                  <div class="row">
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="packageCode">รหัสแพ็คเกจ :</label>
                        <input
                          v-model="formpackage.packageCode"
                          type="text"
                          class="form-control"
                          placeholder="รหัสแพ็คเกจ"
                          :class="{
                            'is-invalid':
                              submitformPackage &&
                              $v.formpackage.packageCode.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitformPackage &&
                              $v.formpackage.packageCode.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.formpackage.packageCode.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="nameTh">ชื่อแพ็คเกจ (ไทย) :</label>
                        <input
                          v-model="formpackage.nameTh"
                          type="text"
                          class="form-control"
                          placeholder="ชื่อแพ็คเกจ"
                          :class="{
                            'is-invalid':
                              submitformPackage && $v.formpackage.nameTh.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitformPackage && $v.formpackage.nameTh.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.formpackage.nameTh.required">
                            {{ error }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="nameEn">ชื่อแพ็คเกจ (อังกฤษ) :</label>
                        <input
                          v-model="formpackage.nameEn"
                          type="text"
                          class="form-control"
                          placeholder="ชื่อแพ็คเกจ"
                          :class="{
                            'is-invalid':
                              submitformPackage && $v.formpackage.nameEn.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitformPackage && $v.formpackage.nameEn.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.formpackage.nameEn.required">
                            {{ error }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--//* End Rows -->

                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label for="descriptionTh">คำอธิบาย (ไทย) :</label>

                        <textarea
                          v-model="formpackage.descriptionTh"
                          rows="3"
                          class="form-control"
                          style="
                          margin-top: 0px;
                          margin-bottom: 0px;
                          height: 115px;
                        "
                          :class="{
                            'is-invalid':
                              submitformPackage &&
                              $v.formpackage.descriptionTh.$error,
                          }"
                        >
                        </textarea>

                        <div
                          v-if="
                            submitformPackage &&
                              $v.formpackage.descriptionTh.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.formpackage.descriptionTh.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label for="descriptionEn">คำอธิบาย (อังกฤษ) :</label>

                        <textarea
                          v-model="formpackage.descriptionEn"
                          rows="3"
                          class="form-control"
                          style="
                          margin-top: 0px;
                          margin-bottom: 0px;
                          height: 115px;
                        "
                          :class="{
                            'is-invalid':
                              submitformPackage &&
                              $v.formpackage.descriptionEn.$error,
                          }"
                        >
                        </textarea>

                        <div
                          v-if="
                            submitformPackage &&
                              $v.formpackage.descriptionEn.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.formpackage.descriptionEn.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3">
                      <b-form-checkbox
                        v-model="formpackage.useInGs"
                        value="1"
                        unchecked-value="0"
                        class="mb-3"
                        checked
                        plain
                        >ส่งข้อมูลไปทุกสาขา (Gs)</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="formpackage.useInBp"
                        value="1"
                        unchecked-value="0"
                        class="mb-3"
                        plain
                        >ใช้กับศูนย์ซ่อมสีและตัวถัง (Bp)</b-form-checkbox
                      >

                      <b-form-checkbox
                        v-model="formpackage.useInWc"
                        value="1"
                        unchecked-value="0"
                        class="mb-3"
                        checked
                        plain
                        >ใช้กับเคลม Warranty (Wc)
                      </b-form-checkbox>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <code>* </code>
                        <label for="validationTooltiptype">ประเภท :</label><br />
                        <multiselect
                          v-model="formpackage.packageType"
                          :options="rowDataSd"
                          label="nameTh"
                          :class="{
                            'is-invalid':
                              submitformPackage &&
                              $v.formpackage.packageType.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="
                            submitformPackage &&
                              $v.formpackage.packageType.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.formpackage.packageType.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>
              </form>
            </b-overlay>
          </div>

          <!-- //!end card -->
          <!-- //!Start Vm -->
          <div class="card">
            <!--  -->
            <b-overlay
              :show="showOverlayFormPackageVm"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <form class="needs-validation" @submit.prevent="formpackage">
                <div class="card-body">
                  <h4 class="card-title">
                    รุ่นรถที่สามารถใช้ได้ในแพ็คเกจ <code>(Auto save)</code>
                  </h4>
                  <hr />

                  <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-5">
                        <div class="mb-3 position-relative">
                          <code>* </code>
                          <label for="model">เพิ่มรุ่นรถ :</label><br />
                          <multiselect
                            v-model="modelType"
                            :options="Model"
                            label="nameEn"
                            @input="postVm()"
                          >
                          </multiselect>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-12 col-md-6">
                          <div
                            id="tickets-table_length"
                            class="dataTables_length"
                          >
                            <label class="d-inline-flex align-items-center">
                              แสดงผล&nbsp;
                              <b-form-select
                                v-model="perPageVm"
                                size="sm"
                                :options="pageOptionsVm"
                                @input="handlePageChangeVm"
                              ></b-form-select
                              >&nbsp;รายการ
                             </label>
                          </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                          <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                          >
                            <label class="d-inline-flex align-items-center">
                              Search:
                              <b-form-input
                                v-model="filterVm"
                                type="search"
                                placeholder="Search..."
                                class="form-control form-control-sm ms-2"
                              ></b-form-input>
                             :</label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="mb-6 position-relative">
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="rowDataVm"
                              :fields="fieldsVm"
                              responsive="sm"
                              :per-page="perPageVm"
                              :current-page="1"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterVm"
                              :filter-included-fields="filterOnVm"
                              @filtered="onFilteredVm"
                            >
                            
                              <template v-slot:cell(action)="rowDataVm">
                                <!-- <a
                                  class="px-2 text-primary"
                                  v-b-tooltip.hover
                                  title="Edit"
                                  @click="putWage(rowDataWagesl)"
                                >
                                  <i class="uil uil-pen font-size-18"></i>
                                </a> -->
                                <a
                                  class="px-2 text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="ConfirmDelVm(rowDataVm)"
                                >
                                  <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                              </template>

                              <!-- End edit table --> </b-table
                            ><br />

                            <div class="row">
                              <div class="col">
                                แสดงผล {{ vmFrom }} - {{ vmTo }} of {{ totalVm }} rows
                              </div>

                              <div class="col">
                                <div
                                  class="
                                  dataTables_paginate
                                  paging_simple_numbers
                                  float-end
                                "
                                >
                                  <ul
                                    class="pagination pagination-rounded mb-0"
                                  >
                                    <!-- pagination -->
                                    <b-pagination
                                      v-model="currentPageVm"
                                      :total-rows="totalVm"
                                      :per-page="perPageVm"
                                      @change="handleChangePageVm"
                                    ></b-pagination>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </form>
            </b-overlay>
          </div>
          <!-- //! Ends Card CarsVm -->
          <div class="card">
            <b-overlay
              :show="showOverlayFormPackageWage"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <form class="needs-validation" @submit.prevent="formpackage">
                <div class="card-body">
                  <h4 class="card-title">
                    ค่าแรงในแพ็คเกจ <code>(Auto save)</code>
                  </h4>
                  <hr />

                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-sm-12 col-md-12">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            แสดงผล&nbsp;
                            <b-form-select
                              v-model="perPageWage"
                              size="sm"
                              :options="pageOptionsWage"
                              @input="handlePageChangeWage"
                            ></b-form-select
                            >&nbsp;รายการ
                           </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-12">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-end"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filterWage"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ms-2"
                            ></b-form-input>
                           :</label>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="mb-6 position-relative">
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="rowDataWage"
                              :fields="fieldsWage"
                              responsive="sm"
                              :per-page="perPageWage"
                              :current-page="1"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterWage"
                              :filter-included-fields="filterOnWage"
                              @filtered="onFilteredWage"
                              ref="selectableTableWage"
                              selectable
                              @row-selected="onRowSelectedWage"
                            >
                            <template #cell(price)="rowDataWage" >
                              <div class="text-end" style="text-align:right">{{rowDataWage.item.price}}</div>
                            </template>
                              <template #cell(selectedWage)="{ selectedWage }">
                                <template v-if="selectedWage">
                                  <span aria-hidden="true">&check;</span>
                                  <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                  <span aria-hidden="true">&nbsp;</span>
                                  <span class="sr-only">Not selected</span>
                                </template>
                              </template> </b-table
                            ><br />

                            <div class="row">
                              <div class="col">
                                แสดงผล {{ wagefrom }} - {{ wageTo }} of {{totalWage}} rows
                              </div>

                              <div class="col">
                                <div
                                  class="
                                  dataTables_paginate
                                  paging_simple_numbers
                                  float-end
                                "
                                >
                                  <ul
                                    class="pagination pagination-rounded mb-0"
                                  >
                                    <!-- pagination -->
                                    <b-pagination
                                      v-model="currentPageWage"
                                      :total-rows="totalWage"
                                      :per-page="perPageWage"
                                      @change="handleChangePageWage"
                                    ></b-pagination>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <!-- <p>
                              <b-button size="sm" @click="selectAllRowsWage"
                                >Select all</b-button
                              >
                              |
                              <b-button size="sm" @click="clearSelectedWage"
                                >Clear selected</b-button
                              >
                            </p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--End table -->

                    <div class="col-md-6">
                      <div class="col-sm-12 col-md-12">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <!-- <label class="d-inline-flex align-items-center">
                            แสดงผล&nbsp;
                            <b-form-select
                              v-model="perPageWagesl"
                              size="sm"
                              :options="pageOptionsWagesl"
                              @input="handlePageChangeWagesl"
                            ></b-form-select
                            >&nbsp;รายการ
                           :</label> -->
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-12">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-end"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filterWagesl"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ms-2"
                            ></b-form-input>
                           :</label>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="mb-6 position-relative">
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="rowDataWagesl"
                              :fields="fieldsWagesl"
                              responsive="sm"
                              :per-page="perPageWagesl"
                              :current-page="1"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterWagesl"
                              :filter-included-fields="filterOnWagesl"
                              @filtered="onFilteredWagesl"
                              @row-selected="onRowSelectedWagesl"
                            >
                              <template #cell(selectedWage)="{ selectedWage }">
                                <template v-if="selectedWage">
                                  <span aria-hidden="true">&check;</span>
                                  <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                  <span aria-hidden="true">&nbsp;</span>
                                  <span class="sr-only">Not selectedsl</span>
                                </template>
                              </template>
                              <!-- edit table -->
                              <template #cell(total_price)="rowDataWagesl" >
                              <div class="text-end" style="text-align:right">{{rowDataWagesl.item.total_price}}</div>
                            </template>
                              <template v-slot:cell(action)="rowDataWagesl">
                                <a
                                  class="px-2 text-primary"
                                  v-b-tooltip.hover
                                  title="Edit"
                                  @click="editPutWage(rowDataWagesl)"
                                >
                                  <i class="uil uil-pen font-size-18"></i>
                                </a>
                                <a
                                  class="px-2 text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="ConfirmDelWage(rowDataWagesl)"
                                >
                                  <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                              </template>

                              <!-- End edit table --> </b-table
                            ><br />

                            <div class="row">

                              <div class="col">
                                <div
                                  class="
                                  dataTables_paginate
                                  paging_simple_numbers
                                  float-end
                                "
                                >
                                  <ul
                                    class="pagination pagination-rounded mb-0"
                                  >
                                    <!-- pagination -->
                                    <b-pagination
                                      v-model="currentPageWagesl"
                                      :total-rows="totalWagesl"
                                      :per-page="perPageWagesl"
                                      @change="handleChangePageWagesl"
                                    ></b-pagination>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div class="text-end">
                    <h4>
                      ยอดรวมค่าแรงในแพ็คเกจ ({{totalWagesl}} รายการ):
                      <span class="badge bg-soft-warning font-size-20">
                        {{ Number(totalPricesWage).toLocaleString() }}
                      </span>
                      บาท
                    </h4>
                  </div>
                </div>
              </form>
            </b-overlay>
          </div>
          <!-- end card -->

          <div class="card">
            <b-overlay
              :show="showOverlayFormPackagePart"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <form class="needs-validation" @submit.prevent="formpackage">
                <div class="card-body">
                  <h4 class="card-title">
                    ค่าอะไหล่ในแพ็คเกจ <code>(Auto save)</code>
                  </h4>
                  <hr />

                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-sm-12 col-md-12">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            แสดงผล&nbsp;
                            <b-form-select
                              v-model="perPagePart"
                              size="sm"
                              :options="pageOptionsPart"
                              @input="handlePageChangePart"
                            ></b-form-select
                            >&nbsp;รายการ
                           </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-12">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-end"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filterPart"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ms-2"
                            ></b-form-input>
                           :</label>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="mb-6 position-relative">
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="rowDataPart"
                              :fields="fieldsPart"
                              responsive="sm"
                              :per-page="perPagePart"
                              :current-page="1"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterPart"
                              :filter-included-fields="filterOnPart"
                              @filtered="onFilteredPart"
                              ref="selectableTablePart"
                              selectable
                              @row-selected="onRowSelectedPart"
                            >
                            <template #cell(costPrice)="rowDataPart" >
                              <div class="text-end" style="text-align:right">{{rowDataPart.item.costPrice}}</div>
                            </template>
                            <template #cell(sellprice)="rowDataPart" >
                              <div class="text-end" style="text-align:right">{{rowDataPart.item.sellPrice}}</div>
                            </template>
                              <template #cell(selectedPart)="{ selectedPart }">
                                <template v-if="selectedPart">
                                  <span aria-hidden="true">&check;</span>
                                  <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                  <span aria-hidden="true">&nbsp;</span>
                                  <span class="sr-only">Not selected</span>
                                </template>
                              </template> </b-table
                            ><br />

                            <div class="row">
                              <div class="col">
                                แสดงผล {{ partFrom }} - {{ partTo }} of {{totalPart}} rows
                              </div>

                              <div class="col">
                                <div
                                  class="
                                  dataTables_paginate
                                  paging_simple_numbers
                                  float-end
                                "
                                >
                                  <ul
                                    class="pagination pagination-rounded mb-0"
                                  >
                                    <!-- pagination -->
                                    <b-pagination
                                      v-model="currentPagePart"
                                      :total-rows="totalPart"
                                      :per-page="perPagePart"
                                      @change="handleChangePagePart"
                                    ></b-pagination>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <!-- <p>
                              <b-button size="sm" @click="selectAllRowsPart"
                                >Select all</b-button
                              >
                              |
                              <b-button size="sm" @click="clearSelectedPart"
                                >Clear selected</b-button
                              >
                            </p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--End table -->

                    <div class="col-md-6">
                      <div class="col-sm-12 col-md-12">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            แสดงผล&nbsp;
                            <b-form-select
                              v-model="perPagePartsl"
                              size="sm"
                              :options="pageOptionsPartsl"
                              @input="handlePageChangePartsl"
                            ></b-form-select
                            >&nbsp;รายการ
                           </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-12">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-end"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filterPartsl"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ms-2"
                            ></b-form-input>
                           :</label>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="mb-6 position-relative">
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="rowDataPartsl"
                              :fields="fieldsPartsl"
                              responsive="sm"
                              :per-page="perPagePartsl"
                              :current-page="1"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterPartsl"
                              :filter-included-fields="filterOnPartsl"
                              @filtered="onFilteredPartsl"
                              @row-selected="onRowSelectedPartsl"
                            >
                              <template
                                #cell(selectedPartsl)="{ selectedPartsl }"
                              >
                                <template v-if="selectedPartsl">
                                  <span aria-hidden="true">&check;</span>
                                  <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                  <span aria-hidden="true">&nbsp;</span>
                                  <span class="sr-only">Not selecteds</span>
                                </template>
                              </template>
                              <template #cell(total_price)="rowDataPartsl" >
                              <div class="text-end" style="text-align:right">{{rowDataPartsl.item.total_price}}</div>
                            </template>
                              <!-- edit table -->
                              <template v-slot:cell(action)="rowDataPartsl">
                                <a
                                  class="px-2 text-primary"
                                  v-b-tooltip.hover
                                  title="Edit"
                                  @click="editPutpart(rowDataPartsl)"
                                >
                                  <i class="uil uil-pen font-size-18"></i>
                                </a>
                                <a
                                  class="px-2 text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="ConfirmDelPart(rowDataPartsl)"
                                >
                                  <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                              </template>

                              <!-- End edit table --> </b-table
                            ><br />

                            <div class="row">
                             

                              <div class="col">
                                <div
                                  class="
                                  dataTables_paginate
                                  paging_simple_numbers
                                  float-end
                                "
                                >
                                  <ul
                                    class="pagination pagination-rounded mb-0"
                                  >
                                    <!-- pagination -->
                                    <b-pagination
                                      v-model="currentPagePartsl"
                                      :total-rows="totalPartsl"
                                      :per-page="perPagePartsl"
                                      @change="handleChangePagePartsl"
                                    ></b-pagination>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="text-end">
                    <h4>
                      ยอดรวมค่าอะไหล่ในแพ็คเกจ ({{totalPartsl}} รายการ):<span
                        class="badge bg-soft-warning font-size-20"
                      >
                        {{ Number(totalPricesPart).toLocaleString() }}
                      </span>
                      บาท
                    </h4>
                  </div>
                </div>
              </form>
            </b-overlay>
          </div>
          <!-- end card -->

          <!-- //! Start Card สรุป -->
          <div class="card">
            <!--  -->
            <b-overlay
              :show="showOverlayFormPackageConclude"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <div class="card-body">
                <h4 class="card-title">
                  <h4>สรุปยอดแพ็คเกจ</h4>
                </h4>
                <hr />
                <div class="row">
                  <div class="col-md-2">
                    รายละเอียดแพ็คเกจ
                  </div>
                  <div class="col-md-3">
                    <div class="text-end">
                      ค่าแรงในแพ็คเกจ : <br />
                      ค่าอะไหล่ในแพ็คเกจ : <br />
                      มูลค่ารวมแพ็คเกจ : <br />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="text-end">
                      {{ Number(totalPricesWage).toLocaleString() }} บาท<br />
                      {{ Number(totalPricesPart).toLocaleString() }} บาท<br />
                      {{ Number(packageTotalPrice).toLocaleString() }} บาท<br />
                    </div>
                  </div>
                  <div
                    class="col-5 bg-dark text-white m-0"
                    style="border-radius:10px;margin:auto; "
                  >
                    <div class="row align-items-center m-0">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-md-3">
                            มูลค่ารวม :
                          </div>
                          <div class="col-6 font-size-24">
                            {{ Number(packageTotalPrice).toLocaleString() }}
                          </div>
                          <div class="col-3">
                            บาท
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br /><br />
                <div class="row">
                  <hr />
                  <div>
                    <button class="btn btn-success" @click="formPackage()">
                      เสร็จสิ้น-กลับสู่หน้าหลัก
                    </button>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
          <!-- end card -->

          <!-- //! Ends Card สรุป  -->
        </b-skeleton-wrapper>

        <b-modal
          title="ระบุจำนวน"
          hide-footer
          size="sm"
          centered
          ref="modalWageAmount"
        >
          <div class="row">
            <div class="col">
              <label for="amount"
                ><code>*</code> {{ WagePostForm.nameTh }} =
                {{ WagePostForm.amountP }}
               :</label>
              <hr />
              <div class="row">
                <div class="col-lg-3">
                  จำนวน :
                </div>
                <div class="col-lg-9">
                  <input
                    type="number"
                    class="form-control"
                    v-model="WagePostForm.amountP"
                    id="amountP"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-12">
              <div class="text-end">
                <button class="btn btn-primary" @click="postWage()">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal
          title="ระบุจำนวน"
          hide-footer
          size="sm"
          centered
          ref="modalPartAmount"
        >
          <div class="row">
            <div class="col">
              <label for="amount"
                ><code>*</code> {{ PartPostForm.nameTh }} =
                {{ PartPostForm.amountP }}
               :</label>
              <hr />
              <div class="row">
                <div class="col-lg-3">
                  จำนวน :
                </div>
                <div class="col-lg-9">
                  <input
                    type="number"
                    class="form-control"
                    v-model="PartPostForm.amountP"
                    id="amountP"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div class="row">
            <div class="col-lg-12">
              <div class="text-end">
                <button class="btn btn-primary" @click="postPart()">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal
          title="แก้ไขชั่วโมงงาน"
          hide-footer
          size="sm"
          centered
          ref="modalEditWageAmount"
        >
          <div class="row">
            <div class="col">
              <label for="amount"
                ><code>*</code> {{ WagePutForm.nameTh }} (ค่าเดิม) =
                {{ WagePutForm.amountDefaultWage }} ชั่วโมง
               :</label>
              <hr />
              <div class="row">
                <div class="col-lg-3">
                  จำนวน :
                </div>
                <div class="col-lg-9">
                  <input
                    type="number"
                    class="form-control"
                    v-model="WagePutForm.amountP"
                    id="amountP"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div class="row">
            <div class="col-lg-12">
              <div class="text-end">
                <button class="btn btn-primary" @click="putWage(rowDataWagesl)">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal
          title="แก้ไขจำนวนอะไหล่"
          hide-footer
          size="sm"
          centered
          ref="modalEditPartAmount"
          @hide="getPackagePart()"
        >
          <div class="row">
            <div class="col">
              <label for="amount"
                ><code>*</code> {{ PartPutForm.nameTh }} (ค่าเดิม) =
                {{ PartPutForm.amountDefaultPart }} ชิ้น
               :</label>
              <hr />
              <div class="row">
                <div class="col-lg-3">
                  จำนวน :
                </div>
                <div class="col-lg-9">
                  <input
                    type="number"
                    class="form-control"
                    v-model="PartPutForm.amountP"
                    id="amountP"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div class="row">
            <div class="col-lg-12">
              <div class="text-end">
                <button class="btn btn-primary" @click="putPart(rowDataPartsl)">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>
